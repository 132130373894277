<template>
  <div class="page-region-edit">
    <div class="mb-5">
      <div class="flex items-center leading-none text-sm">
        <span class="text-red-400">*</span>
        <span class="ml-1 font-bold">区域名称</span>
      </div>
      <div class="flex mt-2 px-2.5 py-4 rounded-md bg-white">
        <input
          class="flex-1 leading-none text-sm"
          type="text"
          placeholder="请输入区域名称"
        >
      </div>
    </div>

    <div class="mb-5">
      <div class="flex items-center leading-none text-sm">
        <span class="ml-1 font-bold">启用状态</span>
      </div>
      <div class="flex items-center mt-2 px-2.5 py-4 rounded-md bg-white">
        <div class="flex-1 leading-none text-sm">选中后的名称样式</div>
        <van-icon size="14px" color="#999" name="arrow" />
      </div>
    </div>

    <div class="submit-btn">提交</div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  name:'RegioEdit',
  components:{
    [Icon.name]:Icon
  }
}
</script>
<style scoped>
.page-region-edit {
  min-height: 100vh;
  background-color: #f6f6f6;
  padding: 15px 12px;
  box-sizing: border-box;
}

</style>
